





























































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { regionPhoneList, checkPassword, checkAccount, checkPhone } from '@/utils'
import { UserGroupSelect } from '@/api/user_group_api';
import AddressBox from '@/view/admin/components/address-box.vue'
import { createUser, getUserInfoById, updateUser } from '@/api/user_api';
import { UserInfo } from '@/interface';
import { GroupSelect } from '@/interface/system';
import { LayoutStoreModule } from '@/store/LayoutModule';
@Component({
  components:{
    AddressBox
  }
})
export default class UserResetPassword extends Vue {
  @Ref('myForm') myForm!: Form;
  @Ref('myForm2') myForm2!: Form;
  regionPhoneList = regionPhoneList;
  groupTypeList: GroupSelect[] = [];
  form: UserInfo= {
    id: '',
    mobile: '',
    name: '',
    nameEn: '',
    gender: '',
    birthday: '',
    company: '',
    position: '',
    mobileAreaCode: '86',
    wechat: '',
    qq: '',
    email: '',
    whatsapp: '',
    zipCode: '',
    address: '',
    fkAreaCityId: 0,
    fkAreaStateId: 0,
    fkAreaCountryId: 0,
    userGroupDtoList: []
  }
  initialAddress = {
    countryId: '',
    stateId: '',
    cityId: '',
  }
  form2: {[key:string]: any} = {
    loginId: '',
    loginPs: '',
    confirmPassword: '',
    isActive: true
  }
  rules = {
    name: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')}
    ],
    nameEn: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')}
    ],
    mobile: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')},
      {validator: this.phoneValidate, trigger: 'blur'}
    ],
    mobileAreaCode: [
      {required: true, trigger: 'change', message: this.$t('pleaseSelectPhoneRegion')}
    ],
    userGroupDtoList:  [
      {required: true, trigger: 'blur', message: this.$t('pleaseSelectUserGroup')}
    ],
  };
  rules2 = {
    loginId: [
      { required: true, trigger: 'blur', message: this.$t('loginAccount')},
      { validator: this.validateAccount, trigger: 'blur'}
    ],
    loginPs: [
      { required: true, trigger: 'blur', message: this.$t('pleaseInput') },
      { validator: this.validatePwd, trigger: 'blur'}
    ],
    confirmPassword: [
      { required: true, trigger: 'blur', message: this.$t('pleaseInput') },
      { validator: this.validateCfmPwd, trigger: 'blur'},
    ]
  }
  myPermission: string[] = [];
  get isPermission(){
    return (str: string) => {
      return this.myPermission.indexOf(str) >= 0;
    }
  }
  get isEdit() {
    return this.$route.params && this.$route.params.id? true: false
  }
  mounted(){
    this.myPermission = this.$route.meta.permission;
    this.getGroupSelect();
    if(this.$route.params.id){
      this.getUserInfo(parseInt(this.$route.params.id));
    }
  }
  async phoneValidate(rule: any, value: string, callback: Function){
    let {validate, tip} = await checkPhone(value, this.form.mobileAreaCode as string, this.form.id as number);
    if (!validate){
      return callback(new Error(tip));
    }
    callback()
  }
  async getUserInfo (id: number){
    const ret = await getUserInfoById(id);
    if(ret.data.code == 1003){
      const userInfo = ret.data.data;
      userInfo.birthday = userInfo.birthday && userInfo.birthday.split(' ')[0]
      userInfo.userGroupDtoList =userInfo.userGroupDtoList? userInfo.userGroupDtoList.map((item:GroupSelect) => item.id): [];
      if (userInfo.fkAreaCountryId) {
        const initialAddress = {
          countryId: userInfo.fkAreaCountryId,
          stateId: '',
          cityId: '',
        }
        if(userInfo.fkAreaStateId){
          initialAddress.stateId = userInfo.fkAreaStateId;
          if(userInfo.fkAreaCityId){
            initialAddress.cityId = userInfo.fkAreaCityId;
          }
        }
        this.initialAddress = initialAddress;
      }
      this.form = {
        id: userInfo.id,
        mobile: userInfo.mobile,
        name: userInfo.name,
        nameEn: userInfo.nameEn,
        gender: userInfo.gender,
        birthday: userInfo.birthday,
        company: userInfo.company,
        position: userInfo.position,
        mobileAreaCode: userInfo.mobileAreaCode,
        wechat: userInfo.wechat,
        qq: userInfo.qq,
        email: userInfo.email,
        whatsapp: userInfo.whatsapp,
        zipCode: userInfo.zipCode,
        address: userInfo.address,
        fkAreaCityId: userInfo.fkAreaCityId,
        fkAreaStateId: userInfo.fkAreaStateId,
        fkAreaCountryId: userInfo.fkAreaCountryId,
        userGroupDtoList: userInfo.userGroupDtoList
      }
      this.form2.isActive = userInfo.isActive;
      this.form2.loginId = userInfo.loginId;
    }
  }
  resetAllForm(){
    this.$nextTick(()=>{
      for (let key in this.form){
        if(key === 'mobileAreaCode'){
          this.form[key] = '86';
          continue;
        }
        this.form[key] = '';
      }
      for (let key in this.form2){
        if(key === 'isActive'){
          this.form2[key] = true;
          continue;
        }
        this.form2[key] = '';
      }
    })
    
  }
  validatePwd(rule: any, value: string, callback: Function){
    if(!checkPassword(value)){
      callback(new Error(this.$t('register_login_tip') + ''));
      return;
    }
    callback();
  }
  validateCfmPwd(rule: any, value: string, callback: Function){
    if(value !== this.form2.loginPs){
      callback(new Error(this.$t('two_password_not_equal') + ''));
      return;
    }
    callback();
  }
  async validateAccount(rule: any, value: string, callback: Function){
    const {validate, tip} = await checkAccount(value, this.form.id as number);
    if(validate){
      return callback();
    }
    callback(new Error(tip));
  }
  async getGroupSelect(){
    const ret = await UserGroupSelect();
    if(ret.data.code == 1003 && ret.data.data && ret.data.data.length){
      this.groupTypeList = ret.data.data;
    }
  }
  resetValidate(str: string){
    this.myForm.clearValidate(str);
  }
  close(){
    this.initialAddress.countryId = '';
    this.initialAddress.stateId = '';
    this.initialAddress.cityId = '';
    if(!this.form.id) {
      for(let key in this.form){
        if( this.form[key] === null || this.form[key] === undefined){
          this.form[key] = '';
          continue;
        }
        let type = this.form[key].constructor.toString();
        if (type.indexOf('Number') >= 0) {
          this.form[key] = 0;
        } else if (type.indexOf('String') >= 0) {
          this.form[key] = '';
        } else if (type.indexOf('Array') >= 0){
          this.form[key] = [];
        }
      }
      for(let key in this.form2){
        if( this.form[key] === null || this.form[key] === undefined){
          this.form[key] = '';
          continue;
        }
        let type = this.form2[key].constructor.toString();
        if (type.indexOf('String') >= 0) {
          this.form2[key] = '';
        } else if (type.indexOf('Boolean') >= 0){
          this.form2[key] = true;
        }
      }
    }
    
    LayoutStoreModule.removeNavItem(LayoutStoreModule.navItemActive);
  }
  submit(){
    if (this.$route.name == 'adminUserAdd'){
      this.submitAdduser();
    } else {
      this.submitUpdateUser();
    }
  }
  async submitAdduser(){
    try {
      await this.myForm.validate();
      await this.myForm2.validate();
      
      const form = Object.assign({fkPlatformType: "get_ib"}, this.form, this.form2, {
        fkAreaCountryId: parseInt(this.initialAddress.countryId),
        fkAreaStateId: parseInt(this.initialAddress.stateId),
        fkAreaCityId: parseInt(this.initialAddress.cityId),
        userGroupDtoList: this.form.userGroupDtoList? this.form.userGroupDtoList.map(id => ({id: id})) : [],
        fkPlatformType: "get_ib", 
        isActive: this.form2.isActive,
      })
      const ret = await createUser(form);
      if(ret.data.code == 1003){
        this.$message.success(this.$t('sthSuccess',{sth: this.$t('userAdd')})+'');
        this.close();
      }
    } catch (e) {
    }
  }
  async submitUpdateUser(){
    try {
      await this.myForm.validate();
      
      const form = Object.assign({}, this.form, {
        fkAreaCountryId: parseInt(this.initialAddress.countryId),
        fkAreaStateId: parseInt(this.initialAddress.stateId),
        fkAreaCityId: parseInt(this.initialAddress.cityId),
        userGroupDtoList: this.form.userGroupDtoList? this.form.userGroupDtoList.map(id => ({id: id})) : [],
        fkPlatformType: "get_ib", 
        isActive: this.form2.isActive,
      })
      if(this.form2.loginPs){
        await this.myForm2.validate();
        form.loginPs = this.form2.loginPs;
      }
      const ret = await updateUser(form);
      if(ret.data.code == 1003){
        this.$message.success(this.$t('sthSuccess',{sth: this.$t('userDetailUpdate')})+'');
        this.close();
      }
    } catch(e) {
    }
  }
}
